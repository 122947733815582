/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Laguna Farms",
  gtagId: "G-JTRLDJL996",
  logo: {
    src: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/Laguna Farms Logo- Blck@4x.png",
    alt: "Laguna Farms Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "16852 Idaho Center Blvd",
    cityStateZip: "Nampa, ID 83687",
    phone: "(208) 505-6676",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/logo_white@2x.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/bullet point laguna logo.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/lagunafarms/Website Photos/_DSC0413-Edit.jpg",
      video: undefined,
      title: "Now Leasing",
      subtitle: undefined,
    },
    aboutUs: {
      paragraphs: [
        "With over 30 different parks across the city and a beautiful Recreation Center, Nampa is the perfect place for your next home. Nampa is full of bustling restaurants and growing businesses. Just West of Lake Lowell Park, those that call Nampa, ID home can enjoy picnics, Barbecues, a disc golf course, and the cool water during the summer months. Nampa is just 20 miles outside of the capital city of Boise, a short drive from charming Meridian, and home to Laguna Farms Townhomes.",
        // "Residents of Laguna Farms enjoy access to exclusive amenities, including a well-equipped fitness center, temperature-controlled pool, and a gorgeous clubhouse. With an exciting playground for children and a dog park for your pets, Laguna Farms is a family-friendly community. Our 3 different models of townhomes include open floorplans, tons of natural light, attached garages and more.",
        "Laguna Farms is a family-friendly community. Our 3 different models of townhomes include open floorplans, tons of natural light, attached garages and more.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/stockphotos/living room.jpg",
        alt: "Staged Living Room",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/lagunafarms/3storypayton/Laguna Payton's-01.jpg",
          alt: "Payton Model Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=yftUZvDJBgF",
          title: "Payton model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/lagunafarms/2storydelinda/Laguna Delinda-01.jpg",
          alt: "Delinda Model Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=Rpwhu7yof4v",
          title: "Delinda model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/lagunafarms/2storytucker/Laguna Tucker.jpg",
          alt: "Tucker Model Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=cVi1NQoL4gQ",
          title: "Tucker model 3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/yellow backpack hiker.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/lagunafarms/Website Photos/_DSC0409-Edit.jpg",
        alt: "Laguna Farms playground",
      },
      {
        src: "https://cdn.maxxpm.com/lagunafarms/Website Photos/FT9A6030-2.jpg",
        alt: "Laguna Farms Kitchen",
      },
      {
        src: "https://cdn.maxxpm.com/lagunafarms/Website Photos/_DSC0454-Edit.jpg",
        alt: "Laguna Farms garages",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/white dog blue couch.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/lagunafarms/Website Photos/_DSC0413-Edit.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-LF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/girls on bed with dog.jpg",
    },
    dnaRequired: true,
  },
};
